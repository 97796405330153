import React, {useCallback, useContext, useEffect, useState, useRef} from "react";
import axios from "axios";
import config from "../../config";

//components
import UserContext from "../common/UserContext";
import {ColumnMenu, NoWrapCell} from "../common/Grid";

//multilingual
import {
    addLicenseKey,
    confirmationCodeKey,
    descriptionKey,
    transportLicenseKey,
    hostIdKey,
    licenseIdKey,
    mainMessages,
    genericEmptyGridKey,
    productKey,
    qtyKey,
    saveToKeysightSoftwareManagerKey,
    statusKey,
    enterSelectLicensesKey
} from "../../assets/text/MultilingualText";

//kendo
import {useLocalization} from "@progress/kendo-react-intl";
import {Label} from "@progress/kendo-react-labels";
import {InputClearValue, TextBox} from "@progress/kendo-react-inputs";
import {Icon} from "@progress/kendo-react-common";
import {Button} from "@progress/kendo-react-buttons";
import {Grid, GridColumn, GridNoRecords} from "@progress/kendo-react-grid";
import {Checkbox} from "@progress/kendo-react-inputs";
import {filterBy, process} from "@progress/kendo-data-query";


export default function EnterLicenses(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        xAuthToken,
        setIsLoading,
        changeStepActivation,
        addLicenses,
        addForm,
        directions,
        licenses,
        setLicenses,
        setTransport,
        setSaveError,
        setNoLongerValid,
        setGeneralError,
        removeAlerts,
    } = props

    const uniqueID = 'license_id'

    const [licenseID, setLicenseID] = useState('')
    const onChangeLicenseID = (event) => {
        setLicenseID(event.target.value)
    }
    const licenseIDClear = () => {
        setLicenseID('')
    }
    const [confirmation, setConfirmation] = useState('')
    const onChangeConfirmation = (event) => {
        setConfirmation(event.target.value)
    }
    const confirmationClear = () => {
        setConfirmation('')
    }

    //States and functions for pagination
    const initialGridState = {
        take: 10, skip: 0,
    };
    const [gridState, setGridState] = useState(initialGridState);
    const gridStateRef = useRef(initialGridState)
    const [dataState, setDataState] = useState(
        process(licenses
            .map((item) => ({
                ...item,
            })), initialGridState)
    );

    const onDataStateChange = useCallback((event) => {
        removeAlerts()
        const newDataState = process(
            licenses
                .map((item) => ({
                    ...item,
                })),
            event.dataState
        );
        //Change header checkbox if filter changes
        setSelectHeaderCheck(newDataState.data.filter(item => (!item.selected && item['status'].toLowerCase() === 'available')).length === 0)
        gridStateRef.current = event.dataState
        setGridState(event.dataState);
        setDataState(newDataState);
    }, [licenses]);

    useEffect(() => {
        const newDataState = process(
            licenses
                .map((item) => ({
                    ...item,
                })),
            gridState
        );
        setDataState(newDataState);
    }, [licenses]) // eslint-disable-line react-hooks/exhaustive-deps

    //Cell and functions for selection
    const SelectCell = (props) => {
        if (props.dataItem['status'].toLowerCase() === 'available') { //TODO confirm variable
            return <td>
                <Checkbox
                    type="checkbox"
                    onChange={props.selectionChange}
                    value={props.dataItem[props.field]}
                />
            </td>
        } else {
            return (<td/>)
        }
    }
    const [selectHeaderCheck, setSelectHeaderCheck] = useState(false);
    const disableHeaderClass = 'ksm-deactivate-button'
    const [selectHeaderClass, setSelectHeaderClass] = useState(disableHeaderClass)

    // disable header checkbox if there are no available license
    useEffect(() => {
        let allDisabled = true
        for (const item of licenses) {
            if (item.status.toLowerCase() === 'available') {
                allDisabled = false
            }
        }
        if (allDisabled) {
            setSelectHeaderClass(disableHeaderClass)
        } else {
            setSelectHeaderClass('')
        }
    }, [licenses])

    const onSelectionChange = useCallback((event) => {
        removeAlerts()
        const checked = event.syntheticEvent.target.checked
        let newLicenses = licenses.map(item => {
            if (event.dataItem[uniqueID] === item[uniqueID]) {
                item.selected = checked
            }
            return item
        })
        setSelectHeaderCheck(filterBy(newLicenses, gridStateRef.current.filter).filter(item =>
            (!item.selected && item['status'].toLowerCase() === 'available')).length === 0)
        setLicenses(newLicenses)
    }, [licenses]); // eslint-disable-line react-hooks/exhaustive-deps


    const onHeaderSelectionChange = useCallback(event => {
        removeAlerts()
        const checked = event.nativeEvent.target.checked;
        let newLicenses = licenses.map(item => {
            for (const filtered of filterBy(licenses, gridStateRef.current.filter)) {
                if (item[uniqueID] === filtered[uniqueID] && item['status'].toLowerCase() === 'available') {
                    item.selected = checked
                }
            }
            return item
        });
        setSelectHeaderCheck(checked)
        setLicenses(newLicenses)
    }, [licenses]); // eslint-disable-line react-hooks/exhaustive-deps

    const validateBanked = () => {
        removeAlerts()

        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Auth-Token': xAuthToken
        };

        let selectedLicenses = licenses.filter(item => item.selected).map((item) => {
            return {
                license_id: item.license_id.toString(),
                confirmation_code: item.confirmation_code
            }
        })

        let data = {
            "module": "LICENSE",
            "sub_module": "BANK",
            "action": "VALIDATE",
            "input_json": [
                {
                    "values": selectedLicenses
                }
            ]
        }
        let formData;
        formData = new FormData();
        formData.append('File', '');
        formData.append('Data', JSON.stringify(data));
        axios.post(
            config.transport.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    changeStepActivation(2)
                    setTransport(false)
                }
            })
            .catch((error) => {
                console.log("ERROR: Validate banked license", error.response);
                if (error.response.data.hasOwnProperty('failed_out_resp')) {
                    let response = error.response.data['failed_out_resp']
                    let invalidSave = []
                    let invalidBank = []
                    for (const item of response) {
                        if (item['error_code'] === 'MOD_TRANSPORT_3019') {
                            invalidBank.push(item['lic'])
                        }
                        if (item['error_code'] === 'MOD_TRANSPORT_3017') {
                            invalidSave.push(item['lic'])
                        }
                    }
                    setSaveError(invalidSave)
                    setNoLongerValid(invalidBank)
                } else {
                    setGeneralError(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const validateTransport = () => {
        removeAlerts()

        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Auth-Token': xAuthToken
        };

        let selectedLicenses = licenses.filter(item => item.selected).map((item) => {
            return {
                license_id: item.license_id.toString(),
                confirmation_code: item.confirmation_code
            }
        })

        let data = {
            "module": "LICENSE",
            "sub_module": "TRANSPORT",
            "action": "VALIDATE",
            "input_json": [
                {
                    "values": selectedLicenses
                }
            ]
        }
        let formData;
        formData = new FormData();
        formData.append('File', '');
        formData.append('Data', JSON.stringify(data));
        axios.post(
            config.transport.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setTransport(true)
                    changeStepActivation(1)
                }
            })
            .catch((error) => {
                console.log("ERROR: Validate banked license", error.response);
                if (error.response.data.hasOwnProperty('failed_out_resp')) {
                    let response = error.response.data['failed_out_resp']
                    let invalidTransport = []
                    for (const item of response) {
                        if (item['error_code'] === 'MOD_TRANSPORT_3019') {
                            invalidTransport.push(item['lic'])
                        }
                    }
                    setNoLongerValid(invalidTransport)
                } else {
                    setGeneralError(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.938rem',
        }}>
            <div>
                <div className={"k-h4"}>
                    {localization.toLanguageString(enterSelectLicensesKey, mainMessages[siteLanguageDefault][enterSelectLicensesKey])}
                </div>
                <div style={{
                    color: '#424242',
                    fontSize: '0.875rem',
                    fontStyle: 'italic',
                }}>
                    {directions}
                </div>
            </div>

            {addForm && (
                <div style={{
                    display: 'flex',
                    gap: '0.938rem',
                    alignItems: 'end',
                }}>
                    <div style={{
                        width: '16.875rem'
                    }}>
                        <Label style={{
                            fontSize: '0.875rem',
                        }}>
                            {localization.toLanguageString(licenseIdKey, mainMessages[siteLanguageDefault][licenseIdKey])}:
                        </Label>
                        <TextBox
                            style={{
                                borderColor: '#AAAAAA'
                            }}
                            value={licenseID}
                            onChange={onChangeLicenseID}
                            suffix={() => (
                                <>
                                    {licenseID !== '' && (
                                        <InputClearValue onClick={licenseIDClear}>
                                            <Icon name="x"/>
                                        </InputClearValue>
                                    )}
                                </>
                            )}
                        />
                    </div>
                    <div style={{
                        width: '16.875rem'
                    }}>
                        <Label style={{
                            fontSize: '0.875rem',
                        }}>
                            {localization.toLanguageString(confirmationCodeKey, mainMessages[siteLanguageDefault][confirmationCodeKey])}:
                        </Label>
                        <TextBox
                            style={{
                                borderColor: '#AAAAAA'
                            }}
                            value={confirmation}
                            onChange={onChangeConfirmation}
                            suffix={() => (
                                <>
                                    {confirmation !== '' && (
                                        <InputClearValue onClick={confirmationClear}>
                                            <Icon name="x"/>
                                        </InputClearValue>
                                    )}
                                </>
                            )}
                        />
                    </div>

                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"outline"}
                        type={"button"}
                        rounded={"small"}
                        onClick={() => {
                            addLicenses([licenseID.trim()], [confirmation.trim()])
                        }}
                        disabled={licenseID.trim() === '' || confirmation.trim() === ''}
                        style={{
                            height: '1.875rem'
                        }}>
                        {localization.toLanguageString(addLicenseKey, mainMessages[siteLanguageDefault][addLicenseKey])}
                    </Button>
                </div>
            )}

            <Grid
                className={'enter-transport-grid'}
                scrollable={'none'}
                data={dataState}
                sortable={true}
                //pagination
                pageable={(licenses.length <= initialGridState.take) ? false : {
                    buttonCount: 5,
                    pageSizes: [10, 20, 50, 100],
                }}
                onDataStateChange={onDataStateChange}
                total={dataState.total}
                //select checkboxes
                onHeaderSelectionChange={onHeaderSelectionChange}
                onSelectionChange={onSelectionChange}
                selectedField={'selected'}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: 'multiple'
                }}
                {...gridState}
            >
                <GridNoRecords>
                    {localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey])}
                </GridNoRecords>
                <GridColumn
                    field={'selected'}
                    cell={SelectCell}
                    headerSelectionValue={selectHeaderCheck}
                    headerClassName={selectHeaderClass}
                />
                <GridColumn
                    field="license_id"
                    title={localization.toLanguageString(licenseIdKey, mainMessages[siteLanguageDefault][licenseIdKey])}
                    columnMenu={ColumnMenu}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="confirmation_code"
                    title={localization.toLanguageString(confirmationCodeKey, mainMessages[siteLanguageDefault][confirmationCodeKey])}
                    columnMenu={ColumnMenu}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="host_id"
                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                    columnMenu={ColumnMenu}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="prod_num"
                    title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                    columnMenu={ColumnMenu}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="prod_desc"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="qty_redeemed"
                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                    columnMenu={ColumnMenu}
                    filter={'numeric'}
                />
                <GridColumn
                    field="status"
                    title={localization.toLanguageString(statusKey, mainMessages[siteLanguageDefault][statusKey])}
                    columnMenu={ColumnMenu}
                />
            </Grid>

            <div style={{
                display: 'flex',
                gap: '0.938rem',
                justifyContent: 'end',
            }}>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    rounded={"small"}
                    onClick={() => {
                        validateTransport()
                    }}
                    disabled={licenses.filter(item => item.selected).length === 0}
                >
                    {localization.toLanguageString(transportLicenseKey, mainMessages[siteLanguageDefault][transportLicenseKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    rounded={"small"}
                    onClick={() => {
                        validateBanked()
                    }}
                    disabled={licenses.filter(item => item.selected).length === 0}
                >
                    {localization.toLanguageString(saveToKeysightSoftwareManagerKey, mainMessages[siteLanguageDefault][saveToKeysightSoftwareManagerKey])}
                </Button>
            </div>


        </div>
    )
}